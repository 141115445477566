<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />

      <h2 class="brand-text text-primary ml-1">
        MyRESSIF
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          <b-spinner label="Chargement..." /> Préparation 🛠
        </h2>
        <p class="mb-3">
          Veuillez patienter, nous chargeons vos données
        </p>

        <!-- img -->
        <b-img
          fluid
          :src="imgUrl"
          alt="login callback page"
        />

        <div class="mt-2 pt-2">
          <b-button variant="primary" @click="goToHome()">Ca tourne en boucle</b-button>
        </div>
      </div>
    </div>
    <!-- <feeling-modal @feeling-posted="goToHome()" /> -->
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg, BSpinner, BButton
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import useUserDroits from '@/auth/useUserDroits'
// import FeelingModal from '@/views/feeling/FeelingModal.vue'
import authService from "@/auth/auth.service";

export default {
  components: {
    BLink,
    BImg,
    BSpinner,
    BButton,
    VuexyLogo,
    // FeelingModal
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  methods: {
    goToHome() {
      localStorage.clear()
      this.$router.push({ name: 'home' })
    },
    checkFeeling: async function () {
      await this.$http.myressif.feeling.getFeeling().then((response) => {
        if (response.humeur == 0 || response.humeur == null) {
          this.$bvModal.show("feeling-modal");
        }
        else {
          this.$router.push({ name: 'home' })
        }
      });
    },
    goToHome() {
      this.$router.push({ name: 'home' })
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  async created() {
    this.$authService
      .loginCallback()
      .then(async (user) => {
        this.$store.dispatch('user/setMe', {
          displayName: user.profile.given_name,
          userName: user.profile.name,
          email: user.profile.email,
          uid: user.profile.sub
        })
        
        const { fetchDroits } = useUserDroits()
        fetchDroits()

        const userLogged = await authService.getUser();
        const tokenExpired = await authService.willTokenExpire();
        const isLoggedIn = userLogged != null && userLogged.access_token && !tokenExpired;
        if (isLoggedIn) {
          // await this.checkFeeling();
          await this.$store.dispatch("user/updateProfile");
          
          this.$router.push({ name: 'home' })
        }
      })
      .catch(err => {
        if(err.message.indexOf('No matching state found in storage') >= 0) {
          console.log('no matching state, will reload')
          this.$authService.login();
        }
        
        console.log('fail', err);
        console.log(err);
      })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
